import React, { useEffect, useMemo, useState } from "react";
import ToomarContainer from "../containers/ToomarContainer";
import "./Product.scss";
import ToomarCart from "../Utils/ToomarCart";
import { Swiper, SwiperSlide } from "swiper/react";
import CircleClose from "../../assets/circleClose.svg";
import CommentIcon from "../../assets/commentIcon.svg";
import whiteMessage from "../../assets/whiteMessage.svg";
import whiteWhatsapp from "../../assets/whiteWhatsapp.svg";
import WhiteTelegram from "../../assets/whiteTelegram.svg";
import Logo from "../../assets/toomarLogo.svg";
import ManAvatar from "../../assets/manAvatar.svg";
import WomanAvatar from "../../assets/avatarWoman.png";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";

// import required modules
import SwiperCore, {
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  Mousewheel,
} from "swiper";
import {
  getAllProducts,
  getCategoriesTree,
  getOneProduct,
  getOneProductCard,
  getOneProductDigital,
  getRecommendations,
  sentComment,
  setBuyingProduct,
  setFavoriteProducts,
  setProductObjects,
} from "../../redux/actions";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ToomarInput from "../kit/ToomarInput";
import ToomarTextarea from "../kit/ToomarTextarea";
import moment from "jalali-moment";
import Categories from "../gallery/Categories";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { baseUrl } from "../../constants";
import { flat } from "../Utils/convertTreeToFlat";

const defaultTitle =
  "Toomar | The largest online store of wedding cards and products";
const defaultDescription =
  "Buy everything you need for a wedding ceremony at the best price from Tomar! The most diverse printed and digital wedding cards, wedding favors, wedding stationery, and sets... World wide shipping";

SwiperCore.use([Navigation, Pagination, Mousewheel]);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "700px",
    // height: 'var(--dialog-height)',
    borderRadius: "24px",
    "@media (max-width:800px)": {
      width: "100%",
      margin: "0px !important",
    },
  },
}));

function reduceCats(prv, cur) {
  if (!cur) return prv;
  return [...prv, cur, ...cur?.children.reduce(reduceCats, [])];
}

function Product({ ...props }) {
  const [whichMenu, setWhichMenu] = useState(1);
  const [count, setCount] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const params = useParams();
  const [sameProducts, setSameProducts] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const [addCommentModal, setOpenCommentModal] = useState(false);
  const classes = useStyles();
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [gender, setGender] = useState("man");
  const [galleryCategories, setGalleryCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [visible, setVisible] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showingSrc, setShowingSrc] = useState("");
  const [option, setOption] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [allExtraPrices, setAllExtraPrices] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState({});

  const [title, description] = useMemo(() => {
    const flatCats = galleryCategories?.reduce(reduceCats, []);
    const currentCat = flatCats.filter(
      (item) => item.id.toString() === params.id?.toString()
    )[0];
    if (currentCat)
      return [
        currentCat.title || currentCat.name || defaultTitle,
        currentCat.description || defaultDescription,
      ];
    return [defaultTitle, defaultDescription];
  }, [galleryCategories, params]);

  useEffect(() => {
    const fetchData = async () => {
      await props.getOneProduct(params.id, (data) =>
        data.data.is_digital
          ? props.getOneProductDigital(params.id, (data) =>
              setProductDetails(data.data)
            )
          : data.data.is_card
          ? props.getOneProductCard(params.id, (data) =>
              setProductDetails(data.data)
            )
          : setProductDetails(data.data)
      );
      await props.getRecommendations(params.id, (data) =>
        setSameProducts(data.data.result)
      );
      await props.getCategoriesTree("domain=2", (data) =>
        setGalleryCategories(data.data.result.children)
      );
      setVisible(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (props.favoriteProducts && props.favoriteProducts.length > 0) {
      for (let i = 0; i <= props.favoriteProducts?.length; i++) {
        if (params.id == props.favoriteProducts[i]?.id) {
          setIsFavorite(true);
        }
      }
    }
  }, [props.favoriteProducts]);

  useEffect(() => {
    if (galleryCategories.length > 0 && productDetails.id) {
      let flatArray = flat({ children: galleryCategories });
      setSelectedCategory(
        flatArray.find((item) => item.id === productDetails.category)
      );
    }
  }, [productDetails, galleryCategories]);

  const handleSentComment = () => {
    if (name != "" && familyName != "") {
      let data = {
        product: productDetails.id,
        content: comment,
        email: email,
        name: name + " " + familyName,
        phone_number: phoneNumber,
        gender: gender,
      };
      props.sentComment(
        data,
        () => {
          setOpenCommentModal(false);
          toast("Your comment submitted successfully!");
        },
        () => {
          setOpenCommentModal(false);
          toast.error("مشکلی پیش آمده!");
          clearCommentFields();
        }
      );
    } else {
      toast.error("Fill all fields!");
    }
  };

  const handleGetProduct = () => {
    if (count === 0 && !productDetails.is_card && !productDetails.is_digital) {
      toast.error("Specific the count of product");
    } else if (
      count >= (selectedCategory.minimum_order ?? 20) ||
      productDetails.is_card != true
    ) {
      var locBuyingProducts = [];
      locBuyingProducts = [...props.buyingProducts];
      let extraPrice = 0;
      let allOptions = [];
      option.forEach(
        (item) =>
          (extraPrice = parseInt(extraPrice) + parseInt(item.extraPrice))
      );
      option.forEach((item) => allOptions.push(item.id));
      if (productDetails.is_digital == true) {
        props.setBuyingProduct([
          ...locBuyingProducts,
          {
            data: productDetails,
            count: 1,
            options: allOptions,
            extraPrice: extraPrice * 1,
          },
        ]);
        props.setProductObjects([
          ...props.productObj,
          {
            id: params.id,
            obj: option,
          },
        ]);
        toast("The product has been added to cart successfully");
      } else if (count > (selectedCategory.minimum_order ?? 0)) {
        props.setBuyingProduct([
          ...locBuyingProducts,
          {
            data: productDetails,
            count: count,
            options: allOptions,
            extraPrice: extraPrice * count,
          },
        ]);
        props.setProductObjects([
          ...props.productObj,
          {
            id: params.id,
            obj: option,
          },
        ]);
        toast("The product has been added to cart successfully");
      }
    } else {
      toast.error(
        `The count of this product should be more than ${
          selectedCategory.minimum_order ?? 20
        }`
      );
    }
  };
  const clearCommentFields = () => {
    setName("");
    setFamilyName("");
    setPhoneNumber("");
    setEmail("");
    setComment("");
    setGender("man");
  };

  const handleOptions = (e, keyName) => {
    let locOption = e.target.value.split(",");
    let locAllOptions = [...option];
    let index = locAllOptions.findIndex((item) => item.keyName === keyName);
    if (index === -1) {
      setOption([
        ...option,
        {
          id: locOption[0],
          extraPrice: locOption[1],
          keyName: keyName,
          title: locOption[2],
        },
      ]);
      setAllExtraPrices(allExtraPrices + parseInt(locOption[1]));
    } else {
      setAllExtraPrices(
        parseInt(allExtraPrices) -
          parseInt(option[index].extraPrice) +
          parseInt(locOption[1])
      );
      locAllOptions.splice(index, 1);
      setOption([
        ...locAllOptions,
        {
          id: locOption[0],
          extraPrice: locOption[1],
          keyName: keyName,
          title: locOption[2],
        },
      ]);
    }
  };

  const handleIsFavorite = () => {
    if (isFavorite) {
      let index = props.favoriteProducts.findIndex(
        (item) => item.id == params.id
      );
      let locObject = [...props.favoriteProducts];
      locObject.splice(index, 1);
      props.setFavoriteProducts([...locObject]);
      setIsFavorite(false);
    } else {
      props.setFavoriteProducts([...props.favoriteProducts, productDetails]);
    }
  };

  return (
    <ToomarContainer>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <ToastContainer />
      {showImage ? (
        <Lightbox
          mainSrc={productDetails.images[showingSrc].image}
          nextSrc={
            productDetails.images[
              (showingSrc + 1) % productDetails.images.length
            ].image
          }
          prevSrc={
            productDetails.images[
              (showingSrc + productDetails.images.length - 1) %
                productDetails.images.length
            ].image
          }
          onCloseRequest={() => setShowImage(false)}
          onMovePrevRequest={() =>
            setShowingSrc(
              (showingSrc + productDetails.images.length - 1) %
                productDetails.images.length
            )
          }
          onMoveNextRequest={() =>
            setShowingSrc((showingSrc + 1) % productDetails.images.length)
          }
        />
      ) : null}
      <Dialog
        open={addCommentModal}
        hideBackdrop={false}
        onClose={() => setOpenCommentModal(false)}
        PaperProps={{
          className: classes.root,
        }}
      >
        <div className={"commentContainer"}>
          <div
            onClick={() => {
              setOpenCommentModal(false);
              clearCommentFields();
            }}
          >
            <p>Close</p>
            <img src={CircleClose} />
          </div>
          <img src={CommentIcon} />
          <p>Dear user, for submit your comment fill these fields</p>
          <ToomarInput
            title={"First name"}
            disabled={false}
            inputValue={name}
            onChange={(e) => setName(e.target.value)}
          />
          <ToomarInput
            title={"Last name"}
            disabled={false}
            inputValue={familyName}
            onChange={(e) => setFamilyName(e.target.value)}
          />
          <ToomarInput
            title={"Phone number"}
            disabled={false}
            maxLength={11}
            // errorMessage={phoneNumber.length != 11 ? "تلفن همراه باید ۱۱ رقمی باشد" : ""}
            inputValue={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <ToomarInput
            title={"Email address(not necessary)"}
            disabled={false}
            inputValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div>
            <p>Gender</p>
            <select value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value={"man"}>Male</option>
              <option value={"woman"}>Female</option>
            </select>
          </div>
          <ToomarTextarea
            title={"Comment"}
            disabled={false}
            rows={5}
            inputValue={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <button onClick={handleSentComment}>Submit Comment</button>
        </div>
      </Dialog>
      <div className={visible ? "visible productContainer" : "visible hide"}>
        <div>
          <div>
            <p>Categorize the results</p>
            <div className="categoriesContainer">
              {galleryCategories.map((item) => (
                <Categories
                  goToGalleryPage={true}
                  category={category}
                  data={item}
                  goSearch={(category) =>
                    window.location.replace(`gallery/${category}`)
                  }
                />
              ))}
            </div>
            <div className="suggestions">
              <Swiper
                cssMode={true}
                slidesPerView={1}
                mousewheel={true}
                className={"toomarCardsSwiperSameProductsSidebar"}
              >
                {sameProducts?.map((item, index) => (
                  <SwiperSlide>
                    <ToomarCart isSuggest={true} value={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div>
              <p>{productDetails.title}</p>
              <p>Code: {productDetails.code}</p>
              <div>
                <p>Product Properties</p>
                <div
                  dangerouslySetInnerHTML={{ __html: productDetails.details }}
                />
              </div>
              {productDetails.is_digital == true ? (
                <div />
              ) : (
                <div>
                  <p>Count :</p>
                  <div>
                    <p onClick={() => setCount(parseInt(count) + 1)}>+</p>
                    <input
                      type={"number"}
                      style={{ fontFamily: "sans-serif" }}
                      value={count}
                      onChange={(e) => setCount(e.target.value)}
                    />
                    <p
                      onClick={() =>
                        count >= 1 ? setCount(parseInt(count) - 1) : null
                      }
                    >
                      -
                    </p>
                  </div>
                </div>
              )}
              <div>
                {productDetails.options &&
                  Object.keys(productDetails.options).map((keyName, i) => (
                    <select
                      defaultValue={"kabols"}
                      value={productDetails.options[0]}
                      onChange={(e) => handleOptions(e, keyName)}
                      style={{ marginLeft: "6px" }}
                    >
                      <option disabled value={"kabols"}>
                        {keyName}
                      </option>
                      {productDetails.options[keyName].map((item, index) => (
                        <option
                          value={
                            item.id + "," + item.extra_price + "," + item.value
                          }
                        >
                          {item.value}
                        </option>
                      ))}
                    </select>
                  ))}
              </div>
              <div>
                <p>Price:‌</p>
                <p style={{ fontFamily: "sans-serif" }}>
                  {productDetails.discount == 0
                    ? `${productDetails.price}  ${
                        allExtraPrices > 0 ? " + " + allExtraPrices : ""
                      } $`
                    : `${
                        productDetails.price *
                        ((100 - productDetails.discount) / 100)
                      }  ${allExtraPrices > 0 ? " + " + allExtraPrices : ""} $`}
                </p>
              </div>
              <div>
                {productDetails.discount != 0 ? (
                  <>
                    <p style={{ fontFamily: "sans-serif" }}>
                      {productDetails.price}
                    </p>
                    <p style={{ fontFamily: "sans-serif" }}>
                      {productDetails.discount}٪
                    </p>
                  </>
                ) : null}
              </div>
              <div>
                <button onClick={handleGetProduct}>+ Add to cart</button>
                {/* <button>
                                    <img src={BlackTick} alt={'tick'}/>
                                    <p>
                                        تایید و ثبت اطلاعات
                                    </p>
                                </button> */}
              </div>
              <p>{/* ابعاد 18 در 13 سانتی متر */}</p>
              {productDetails.is_digital == true ? (
                <p></p>
              ) : productDetails.is_card && productDetails.is_card == true ? (
                <p>
                  Note that the number of custom cards is usually one third of
                  the number of guests
                  <p>
                    The minimum number of custom cards in the site is{" "}
                    <span style={{ fontFamily: "sans-serif" }}>20</span>
                  </p>
                </p>
              ) : (
                <p></p>
              )}
            </div>
            <div>
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                  direction: "ltr",
                }}
                spaceBetween={10}
                slidesPerView={1}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                {productDetails.is_clip ? (
                  <video controls style={{ width: "100%", objectFit: "cover" }}>
                    <source src={productDetails.video} />
                  </video>
                ) : (
                  productDetails &&
                  productDetails.images &&
                  productDetails.images.map((item, index) => (
                    <SwiperSlide key={item.image}>
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowingSrc(index);
                          setShowImage(true);
                        }}
                        src={item.image}
                        className={"topImageSwiper"}
                      />
                    </SwiperSlide>
                  ))
                )}
              </Swiper>
              <Swiper
                style={{
                  direction: "ltr",
                }}
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {productDetails.is_clip == true
                  ? null
                  : productDetails &&
                    productDetails.images &&
                    productDetails.images.map((item, index) => (
                      <SwiperSlide>
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowingSrc(index);
                            setShowImage(true);
                          }}
                          src={item.image}
                          className={"bottomImagesSwiper"}
                        />
                      </SwiperSlide>
                    ))}
              </Swiper>
              <div>
                <p>Share this product with your friends</p>
                <a
                  href={`sms://?body=${window.location.href}`}
                  target={"_blank"}
                >
                  <button>
                    <img src={whiteMessage} alt={"message"} />
                  </button>
                </a>
                <a
                  href={`whatsapp://send?text=${window.location.href}`}
                  data-action="share/whatsapp/share"
                  target={"_blank"}
                >
                  <button>
                    <img src={whiteWhatsapp} alt={"whatsapp"} />
                  </button>
                </a>
                <a
                  href={`tg://msg?text=${window.location.href}`}
                  data-action="share/telegram/share"
                  target={"_blank"}
                >
                  <button>
                    <img src={WhiteTelegram} alt={"telegram"} />
                  </button>
                </a>
                <div className="productFavoriteContainer tooltip">
                  <button onClick={handleIsFavorite}>
                    <img
                      src={require(isFavorite
                        ? "../../assets/favorite.png"
                        : "../../assets/non_favorite.png")}
                      alt={"telegram"}
                    />
                  </button>
                  <span class="tooltiptext">
                    {isFavorite ? "Delete from favorite" : "Add to favorite"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {productDetails.description == "" ? (
            <div />
          ) : (
            <div>
              <p>Description</p>
              <div
                className="productDesc"
                style={{ fontFamily: "sans-serif" }}
                dangerouslySetInnerHTML={{ __html: productDetails.description }}
              />
            </div>
          )}

          <div>
            <p>The bride and groom's point of view</p>
            {productDetails &&
              productDetails.comments &&
              productDetails.comments.map((item) => (
                <div>
                  <div>
                    <img
                      src={item.gender === "man" ? ManAvatar : WomanAvatar}
                      alt={"avatar"}
                    />
                    <div>
                      <p>{item.name}</p>
                      <p>{moment(item.created_at).format("jYYYY/jMM/jDD")}</p>
                    </div>
                  </div>
                  <p>{item.content}</p>
                  {/* reply */}
                  {item.reply && (
                    <div>
                      <div>
                        <img src={Logo} alt={"avatar"} />
                        <div>
                          <p>{item.reply.name}</p>
                          <p>
                            {moment(item.reply.created_at).format(
                              "jYYYY/jMM/jDD"
                            )}
                          </p>
                        </div>
                      </div>
                      <p>{item.reply.content}</p>
                    </div>
                  )}
                  {/* reply */}
                </div>
              ))}
            <button onClick={() => setOpenCommentModal(true)}>
              Submit new comment
            </button>
          </div>

          <div>
            {productDetails?.attachments?.length > 0 && (
              <>
                <p>Samples delivered to the customer</p>
                <div>
                  <Swiper
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-pagination-color": "#fff",
                    }}
                    cssMode={true}
                    dir="rtl"
                    slidesPerView={isMobile ? 1 : 3}
                    navigation={true}
                    pagination={{ dynamicBullets: true }}
                    mousewheel={true}
                    className={"toomarCardsSwiperSameProductsVideo"}
                  >
                    {productDetails.attachments?.map((item, index) => (
                      <SwiperSlide>
                        {item.type == "vid" ? (
                          <video
                            controls
                            style={{
                              width: isMobile ? "90%" : "95%",
                              objectFit: "contain",
                              margin: "auto",
                            }}
                          >
                            <source src={baseUrl + item.file} />
                          </video>
                        ) : (
                          <img
                            style={{
                              width: isMobile ? "90%" : "95%",
                              objectFit: "fill",
                              margin: "auto",
                            }}
                            src={item.file}
                          />
                        )}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>
            )}
          </div>

          <div>
            <p>Similar offers</p>
            <div>
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                cssMode={true}
                loop={true}
                dir="rtl"
                slidesPerView={isMobile ? 1 : 3}
                navigation={true}
                pagination={{ dynamicBullets: true }}
                mousewheel={true}
                className={"toomarCardsSwiperSameProducts"}
              >
                {sameProducts?.map((item, index) => (
                  <SwiperSlide>
                    <ToomarCart value={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </ToomarContainer>
  );
}

const mapStateToProps = (state) => ({
  buyingProducts: state.counter.buyingProducts,
  favoriteProducts: state.counter.favoriteProducts,
  productObj: state.counter.productObj,
});

export default connect(mapStateToProps, {
  getOneProduct,
  setProductObjects,
  getAllProducts,
  sentComment,
  setBuyingProduct,
  getRecommendations,
  getCategoriesTree,
  getOneProductDigital,
  getOneProductCard,
  setFavoriteProducts,
})(Product);
