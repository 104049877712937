import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllProducts, getBlogs, getCategories, getSliders } from '../../redux/actions';
import ToomarContainer from '../containers/ToomarContainer';
import BestSelling from './BestSelling';
import HottestOfToomar from './HottestOfToomar';
import RepetitiveQuestions from './RepetitiveQuestions';
import ToomarCardsSwiper from './ToomarCardsSwiper';
import ToomarGallery from './ToomarGallery';
import ToomarMagazine from './ToomarMagazine';
import TopFeatureOfToomar from './TopFeatureOfToomar';
import WhyToomar from './WhyToomar';
import Loading from '../../assets/loadingHome.gif';
import {Helmet} from "react-helmet";
import TakePhotoContainer from './TakePhotoContainer';


function Home({ ...props }) {

    const [hottestOfToomar, setHottestOfToomar] = useState([]);
    const [mostSellingOfToomar, setMostSellingOfToomar] = useState([]);
    const [mainSlider, setMainSlider] = useState([]);
    const [galleryCategories, setGalleryCategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await props.getAllProducts({category: 122, ordering: '-created-at'}, (data) => setHottestOfToomar(data.data.results));
            await props.getAllProducts({category: 122, ordering: '-score'}, (data) => setMostSellingOfToomar(data.data.results));
            await props.getSliders("domain=2", undefined, (data) => setMainSlider(data.data.results));
            await props.getCategories("domain=2", 'parent=122', (data) => setGalleryCategories(data.data.results));
            await props.getBlogs(undefined, (data) => setBlogs(data.data.results));
            setVisible(true)
        }
        fetchData();

    }, {})
    if(visible) {
        return (
            <ToomarContainer>
                <Helmet>
                    <title>Toomar | The largest online store of wedding cards and products</title>
                    <meta name="title" content="Toomar | The largest online store of wedding cards and products" />
                    <meta
                        name="description"
                        content="Buy everything you need for a wedding ceremony at the best price from Tomar! The most diverse printed and digital wedding cards, wedding favors, wedding stationery, and sets... World wide shipping"
                    />
                    <meta property="og:title" content="Toomar wedding online shop" />
                    <meta property="og:description" content="The first online store for wedding cards and wedding items. Simple and fast shopping in a virtual and diverse environment. Toomar wedding card" />
                    <meta property="og:url" content="https://wedding.toomar.co" />
                </Helmet>
                <div className={visible ? 'visible' : 'visible hide'}>
                    <ToomarCardsSwiper data={mainSlider}/>
                    {hottestOfToomar.length != 0 && <HottestOfToomar data={hottestOfToomar}/>}
                    <TopFeatureOfToomar/>
                    {mostSellingOfToomar.length != 0 && <BestSelling data={mostSellingOfToomar}/>}
                    <ToomarGallery galleryCategories={galleryCategories}/>
                    <WhyToomar/>
                    {blogs.length !=0 && <ToomarMagazine data={blogs}/>}
                    <RepetitiveQuestions/>
                    {/* <TakePhotoContainer/> */}
                </div>
            </ToomarContainer>
        )
    }
    else {
        return (
            <div className={'loadingImg'}>
                <img src={Loading}/>
            </div>
        )
    }
    
}

export default connect(null, { getAllProducts, getSliders, getCategories, getBlogs })(Home);