import React from 'react';
import ToomarContainer from '../containers/ToomarContainer';
import Title from '../Utils/Title';
import './AboutUs.scss';
import FollowUsInSocials from '../kit/FollowUsInSocials';
import {Helmet} from "react-helmet";

function AboutUs() {
    return (
        <ToomarContainer>
            <Helmet>
                <title>Toomar about us | The largest online store of wedding cards and products</title>
                <meta name="title" content="Toomar | The largest online store of wedding cards and products" />
                <meta name="description" content="The first online store for wedding cards and wedding items. Simple and fast shopping in a virtual and diverse environment. Toomar wedding card" />
                <meta property="og:title" content="Toomoar about us" />
                <meta property="og:url" content="https://wedding.toomar.co/about-us" />
            </Helmet>
            <Title
            goldText={'About'}
            blackText={'us'}/>
            <div className={'aboutUsContainer'}>
                <p>
                Greetings to all dear ones, especially dear couples. Tomar wedding card online store is at your service as the largest online store of wedding cards and items by creating a suitable and simple environment in virtual space.                </p>
                <p>
                You, dear couples, can order your card with just a few clicks at any time and place, and choose all the details of your card as you like. In addition, we provide all the items needed for various wedding celebrations in the scroll. We have made it so that you can order all the items you need without stress and prepare them for your ceremony with the highest quality.                </p>
                <p style={{margin: "0px"}}>
                    {/* محدوده فعالیت طومار در حال حاضر در ایران در تمامی شهرها و استان ها میباشد و شما از هر جای ایران عزیز که سفارش بدهید تیم حرفه ای طومار همه ی تلاش خود را میکند تا کار شما مشتری عزیز را به نحو احسن انجام دهد .(البته اگر در خارج از ایران هم سکونت دارید میتوانید با شماره های موجود روی سایت تماس بگیرید و محصولات مورد نظر خود را از طریق تماس تلفنی یا واتساپ سفارش دهید.) */}
                </p>
                <p>
The biggest goal of Tomar company is your satisfaction and pleasure, dear customer, the experience of a good purchase, delivery, good quality and your satisfaction is considered our most valuable asset.                </p>
                <p>
                Tomar team considers your comments and suggestions as one of its greatest assets. We are very grateful to the dear couples who share their opinions with us and criticize us. You can register your message in the {<span onClick={() => window.open('/offers-critics/')}>suggestions and criticisms</span>} section and our team will definitely do the necessary checks and your comments are very effective in the process of improving the site and improving our activities.
                </p>
                <p>
                The Tomar site is designed in such a way that you, dear couples, can easily find the wedding card and the items you want, and in the next step, choose the text and poem of your card from the gallery, and then the type of text and Latin print type and title. card and in the next step, register the information and finalize your order, for more information on {<span onClick={() => window.open('/order-guide/')}>how to place an order</span>}, you can refer to the guide for placing an order.
                </p>
                <p>
                Tomar team hopes to have a small contribution in making your ceremony more pleasant and memorable by providing wedding cards and necessary items for your ceremony.                </p>
                <p>
                Sincerely yours: Petition Management (Erfan Mehri)                
                </p>
                <FollowUsInSocials/>
            </div>
                
        </ToomarContainer>
    )
}

export default AboutUs;